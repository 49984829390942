import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import "./style.scss";

const Newsletter = () => {
  useEffect(() => {
    const container = document.getElementById("newsletter-form-container");

    const script = document.createElement("script");
    script.src = "https://welkom-editor.io/js/landing/landing.js";
    script.type = "text/javascript";
    script.async = true;
    script.setAttribute(
      "landing",
      "https://f.info.auxiliaire.fr/f/l/?q=gMh8yxSqyKAtBpo8F_j2qhEsArIRkX4BtuOkd6iXu__e77RUxikem3Q7Y187iIwD6QXYI2-gic7G_Gsp0JXQPZ4XviJF2A5yVsRfMtzc2H6_6M6o24q0wfbX-aBOxMIUG7GMQB_02RyLdWu-dqJFPJBg9SU-RaWsRFtMmEpVZUVq0QaONJjUwsPlc_209KhfeF9jGO7vQ-7lYRKe3AJTiUqSFUBuBw0fQ3m6U03dRtrNZOJTeDNdpJ1n0zS-_LNZ"
    );

    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <body className="Newsletter" />
      </Helmet>
      <Layout>
        <SEO
          title="Newsletter"
          description="Restez informé de nos dernières nouvelles et offres. Abonnez-vous à notre Newsletter."
        />

        <section className="newsletter__form">
          <div id="newsletter-form-container"></div>
        </section>
      </Layout>
    </>
  );
};

export default Newsletter;
